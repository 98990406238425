<template>
  <div>
    <app-base-table
      :tableOptions="tableOptions"
      @re-fetch-data="fetchData"
      @re-fetch-paginated-data="fetchData($event)"
      :serverRoute="'/edu-class'"
      :enableDelete="displayDeleteIcon"
    >
      <template slot="editFeature" slot-scope="{ row }">
        <app-edit-education-class-modal
          v-if="returnAbility('edu-class:update')"
          @recordUpdated="fetchData"
          :recordData="row"
        ></app-edit-education-class-modal>
      </template>
    </app-base-table>
  </div>
</template>

<script>
import editEducationClassModal from "../../education-class/editEducationClassModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    appEditEducationClassModal: editEducationClassModal,
  },
  data() {
    return {};
  },
  props: {
    id: {
      type: [String, Number],
    },
  },
  computed: {
     ...mapGetters({
      tableOptions: "educationClass/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchEducationClasses: "educationClass/fetchEducationClass",
    }),
    fetchData(paginationValue) {
      this.fetchEducationClasses({
        page: paginationValue,
        grade_id: this.id,
      });
    },
  },
  created() {
    if (this.returnAbility("edu-class:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>
</style>