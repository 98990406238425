<template>
  <div>
    <div class="table__spinnerWrapper" v-if="loading">
      <app-spinner></app-spinner>
    </div>
    <div v-else>
      <v-row class="py-10 px-10 d-flex justify-space-between align-center">
        <div>
          <h4>{{ $t("term name") }}</h4>
          <h2>{{ getGradeInfo.name }}</h2>
        </div>
        <div>
          <h4>{{ $t("educational year") }}</h4>
          <h2>{{ getGradeInfo.term.name }}</h2>
        </div>
        <div>
          <h4>{{ $t("inancial installment") }}</h4>
          <h2>{{ getGradeInfo.price }}</h2>
        </div>
        <div>
          <h4>{{ $t("class type") }}</h4>
          <h2>{{ getGradeInfo.grade_type.name }}</h2>
        </div>
        <div>
          <h4>{{ $t("total students") }}</h4>
          <h2>{{ getGradeInfo.registration_record_count }}</h2>
        </div>
      </v-row>
      <v-card elevation="0">
        <v-toolbar>
          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title color="#757575">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-if="returnAbility('edu-class:index')"
                :href="'#' + '1'"
                >{{ $t("education class") }}</v-tab
              >
              <v-tab
                v-if="returnAbility('registration-record:index')"
                :href="'#' + '2'"
                >{{ $t("students") }}</v-tab
              >
              <v-tab v-if="returnAbility('note:index')" :href="'#' + '3'">{{
                $t("notes")
              }}</v-tab>
              <v-tab v-if="returnAbility('test:index')" :href="'#' + '4'">{{
                $t("revision")
              }}</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item value="1">
            <app-grade-educational-classes
              v-if="tab == '1' && returnAbility('edu-class:index')"
              :id="gradeId"
            ></app-grade-educational-classes>
          </v-tab-item>
          <v-tab-item value="2">
            <app-students
              :id="gradeId"
              v-if="tab == '2' && returnAbility('registration-record:index')"
              idType="grade"
            ></app-students>
          </v-tab-item>
          <v-tab-item value="3">
            <app-notes
              v-if="tab == '3' && returnAbility('note:index')"
              :id="gradeId"
              idType="grade"
            ></app-notes>
          </v-tab-item>
          <v-tab-item value="4">
            <app-tests-view
              v-if="tab == '4' && returnAbility('test:index')"
              :id="gradeId"
              idType="grade"
            ></app-tests-view>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import gradeEducationalClasses from "./gradeEducationalClasses.vue";
import students from "../../shared-eduClass&grade/students.vue";
import notes from "../../shared-eduClass&grade/notes/notes.vue";
import testsView from "../../shared-eduClass&grade/tests/testsView.vue";
export default {
  components: {
    appTestsView: testsView,
    appNotes: notes,
    appStudents: students,
    appGradeEducationalClasses: gradeEducationalClasses,
  },
  data() {
    return {
      tab: false,
      gradeId: this.$route.params.gradeId,
      loading: false,
    };
  },
  watch: {
    tab(v) {
      console.log("tab?", v);
    },
  },
  computed: {
    ...mapGetters({
      getGradeInfo: "grade/getGradeInfo",
    }),
  },
  methods: {
    ...mapActions({
      fetchGradeInfo: "grade/fetchGradeInfo",
      fetchGradeEduClasses: "educationClass/fetchEducationClass",
    }),
  },
  created() {
    this.loading = true;
    this.fetchGradeInfo(this.gradeId).finally((_) => {
      this.loading = false;
    });
  },
};
</script>

<style>
</style>